import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql, Link } from "gatsby"
import React from "react"
import { Box, Flex } from "theme-ui"
import Button from "../components/base/buttons"
import Input from "../components/base/input-field"
import Text from "../components/base/text/text"
import ContentModules from "../components/content-modules"
import SEO from "../components/seo"
import useNewsletterSignup from "../hooks/use-newsletter-signup"

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: ${(props) => (props.centerPage ? "center" : "flex-start")};
  flex: 1;
  width: 60%;
  margin: auto;
`

const Conditions = styled(Box)`
  a {
    color: ${(props) => props.theme.colors.darkGreen};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const NewsletterPageTemplate = ({ data }) => {
  let { page } = data
  const { metaDescription, metaTitle } = page

  const theme = useTheme()

  const { handleEmailChange, handleSignup, signingUp, state } =
    useNewsletterSignup()

  return (
    <Container centerPage={page.centerPage}>
      <SEO
        title={metaTitle || "Palmes Tennis Society"}
        description={
          metaDescription?.metaDescription || "Palmes Tennis Society"
        }
      />
      <ContentModules allModules={page.contentModules} />
      <Flex
        sx={{
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
          }}
        >
          <Text>The Palmes Newsletter</Text>
          <Flex
            sx={{
              fontSize: "12px",
              lineHeight: "28px",
              maxWidth: "500px",
              marginTop: 1,
              marginBottom: 2,
            }}
          >
            Sign up to stay in the loop on everything new from the world of
            Palmes, including early access to new collections, collaborations,
            stories and more.
          </Flex>
        </Flex>
        <Flex
          sx={{
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              maxWidth: "500px",
            }}
          >
            {state.signupComplete ? (
              <Flex
                py={0}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 0,
                  height: "50px",
                  width: "272px",
                  backgroundColor: theme.colors.darkGreen,
                  color: theme.colors.almostWhite,
                }}
              >
                Thank you!
              </Flex>
            ) : (
              <Flex py={0} sx={{ fontSize: 0 }}>
                <Input
                  placeholder={"Enter your email"}
                  onChange={handleEmailChange}
                  disabled={state.signupComplete}
                />
                <Button
                  variant="primary"
                  loading={signingUp}
                  disabled={state.signupComplete}
                  sx={{ borderRadius: "0px", width: "110px", height: "50px" }}
                  onClick={handleSignup}
                >
                  <Text>Join</Text>
                </Button>
              </Flex>
            )}
          </Box>
          <Conditions sx={{ fontSize: "8px", maxWidth: "500px", marginTop: 1 }}>
            Sign me up for newsletters and communications from Palmes. By
            signing up, you confirm that you are above 15 years old and have
            reviewed <Link to="/privacy-policy">Privacy Policy</Link>.
            Unsubscribe any time through the link at the bottom of our
            newsletter.
          </Conditions>
        </Flex>
      </Flex>
    </Container>
  )
}

export default NewsletterPageTemplate

export const newsletterPageQuery = graphql`
  query NewsletterPageQuery($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...PageContent
    }
  }
`
